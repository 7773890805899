import { ChevronIcon } from '@/theme/Icons';
import { ButtonProps, IconButton } from '@chakra-ui/react';
import { useScroll } from '@/hooks/useScroll';
import { MotionBox } from '@/components/Motion';
import { getLicenseByHost } from '@/utils/multiDomains';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useCurrentHost } from '@/hooks/useCurrentHost';

const ScrollToTopButton = ({ ...props }: ButtonProps) => {
  const { scrollY } = useScroll();
  const currentHost = useCurrentHost();
  const license = getLicenseByHost(currentHost);
  const isMobileViewAltenar = useMediaQuery('(max-width: lg)');
  return (
    <MotionBox
      id="btn-scroll-to-top"
      initial={{
        position: 'fixed',
        top: 'auto',
        left: 'auto',
        right: '2rem',
        opacity: 0,
        zIndex: 1 // base + 1 === underneath the altenar betslip (4)
      }}
      animate={{
        opacity: scrollY > 0 ? 1 : 0,
        bottom: license === 'F' && isMobileViewAltenar ? '12.5rem' : '6.12rem'
      }}
    >
      <IconButton
        background="scrollToTop"
        border={0}
        aria-label="scroll to top button"
        icon={<ChevronIcon w="2rem" h="2rem" transform="rotate(180deg)" />}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        {...props}
      />
    </MotionBox>
  );
};

export default ScrollToTopButton;
